@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

@import '@design';
.custom-tab {
  border: 1px solid #008045 !important;
}
.custom-tab-active {
  background-color: #ddfe54;
  color: #008045 !important;
}
.fa-beat {
  -webkit-animation: fa-beat 1s infinite linear;
  animation: fa-beat 1s infinite linear;
}
@-webkit-keyframes fa-beat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes fa-beat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
